import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ConfirmationMessageValidationSchema } from '../../../validations/additional/modalDialogs';

// components
import {
  Text,
  Form,
  FormField,
  FormInputTextArea,
  RequiredPropsForFormModel,
  ModalDialog,
  RequiredPropsForModalDialogModel,
} from '@ui';
import styled from 'styled-components';

export interface FormValuesModel {
  reason: string;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  description?: string;
  reasonFieldLabel?: string;
  submitButtonText: string;
  submitButtonDanger?: boolean;
  cancelButtonText?: string;
}

const ConfirmActionModalDialog = ({
  title,
  submitButtonText,
  isVisible,
  description,
  closeCallback,
  onSubmit,
  submitButtonDanger,
  cancelButtonText,
  reasonFieldLabel,
  initialValues,
}: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving={false}
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={ConfirmationMessageValidationSchema}
      renderForm={
        <StyledModalDialog
          width={720}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{
            text: submitButtonText,
            danger: submitButtonDanger,
          }}
          cancelButtonProps={{
            text: cancelButtonText || t('close'),
          }}
        >
          {description ? <Text>{description}</Text> : null}
          <FormField
            label={reasonFieldLabel || t('reason')}
            name="reason"
            component={FormInputTextArea}
            additionalProps={{
              autoSize: { minRows: 6, maxRows: 6 },
            }}
          />
        </StyledModalDialog>
      }
    />
  );
};

// TODO: need to investigate and probably remove this padding globally since the gap is very big
const StyledModalDialog = styled(ModalDialog)`
  .ant-modal-header {
    padding-bottom: 0px;
  }
`;

export default ConfirmActionModalDialog;

import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IDocumentAssociationInfo } from '../../../../../typings/documents/documents';
import { SetupInitialRelationshipsFormValidationSchema } from '../../../../../validations/onboarding-new/applicant-information';
import { FormValuesModel as RelationshipFormValuesModel } from '../../../../Forms/TemplateForms/CRM/RelationshipTemplateForm';

// components
import InnerForm from './InnerForm';
import {
  Form,
  RequiredPropsForFormModel,
  ModalDialog,
  RequiredPropsForModalDialogModel,
} from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

export interface FormValuesModel {
  activeApplicationScopeId: string;

  organizationTypeName: string;
  organizationTypeCategoryId: string;

  relationshipsFromContactId: string;
  relationshipsToOrganizationId: string;

  isPEP: boolean | null;
  pepInformation: string;

  relationships: RelationshipFormValuesModel[];
  documentAssociation: IDocumentAssociationInfo;
}

const SetupInitialRelationshipsModal = ({
  isVisible,
  onSubmit,
  initialValues,
}: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={SetupInitialRelationshipsFormValidationSchema}
      renderForm={
        <ModalDialog
          width={1000}
          closable={false}
          shouldResetFormAfterSubmit={false}
          title={t('personal_info_modal.title')}
          isVisible={isVisible}
          closeCallback={console.log}
          cancelButtonProps={{ hidden: true }}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default SetupInitialRelationshipsModal;

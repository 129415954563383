import React, { ReactNode, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useDispatch } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { CHANGE_VIEW_TYPES, changeView } from 'redux/actions/view';

// components
import { Text, Button, IconSVG, Dropdown, Menu, MenuItem } from '@ui';
import { ReactComponent as ArrowDownIcon } from 'resources/icons/remix-icons/arrow-down-s-line.svg';
import { Tabs as AntTabs } from 'antd';
import { TabsProps } from './types';

const { TabPane } = AntTabs;

// Custom tabs component with additional config
const Tabs: React.FC<TabsProps> = ({
  tabs,
  defaultActiveKey,
  renderOnChange = false,
  activeKey,
  onChange,
  useChangeView,
  ...rest
}) => {
  const visibleTabs = useMemo(
    () => tabs.filter(({ hidden }) => !hidden),
    [tabs],
  );

  const [activeTab, setActiveTab] = useState(getInitialKey());

  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (activeKey && activeKey !== activeTab) {
      setActiveTab(activeKey);
    }
  }, [activeKey]);

  function getInitialKey() {
    if (defaultActiveKey) {
      return defaultActiveKey;
    }

    return visibleTabs.length ? visibleTabs[0].key : '';
  }

  const generateTabs = () => {
    if (visibleTabs.length === 1) {
      return visibleTabs[0].content;
    }

    return visibleTabs.map(({ key, title, content }) => (
      <TabPane key={key.toString()} tab={title}>
        {content}
      </TabPane>
    ));
  };

  const generateTabsForDropdown = () => {
    const buttonNodes = visibleTabs.map(({ key, title }) => (
      <MenuItem key={key} itemKey={key}>
        {title}
      </MenuItem>
    ));

    return (
      <Menu onClick={({ key }) => handleTabChange(key)}>{buttonNodes}</Menu>
    );
  };

  const handleTabChange = (newKey: any) => {
    if (useChangeView) {
      dispatch(
        changeView(() => {
          setActiveTab(newKey);
          onChange && onChange(newKey);
        }, CHANGE_VIEW_TYPES.TABS),
      );
    } else {
      setActiveTab(newKey);
      onChange && onChange(newKey);
    }
  };

  const getActiveTabTitle = (activeKey: string) => {
    let result: string | ReactNode = '';

    if (activeKey) {
      result = visibleTabs.find((e) => e.key === activeKey)?.title || '';
    }

    return result;
  };

  // render tabs depends on current screen
  // here is 2 cases:
  // - default pc/tablet screen where we need to render full tabs
  // - mobile screen where we need to render dropdown in header since it's better from UX
  // (mobile mode starts when the width of the screen is smaller than 768px md-breakpoint)
  const renderTabBar = (props: any, DefaultTabBar: any) => {
    if (breakpoint.md) {
      // render default mobile header tabs
      // set mobile as a true value to allow scrolling tabs horizontally
      return <DefaultTabBar {...props} mobile />;
    } else {
      return (
        <Dropdown overlay={generateTabsForDropdown()}>
          <ActiveTabButton size="large" type="bordered">
            <Text variant="body1">{getActiveTabTitle(activeTab)}</Text>
            <StyledIconSVG
              component={ArrowDownIcon}
              color={colorsTheme.colorWhite}
            />
          </ActiveTabButton>
        </Dropdown>
      );
    }
  };

  return (
    <StyledAntTabs
      {...rest}
      isTabsOne={visibleTabs.length === 1}
      activeKey={activeKey || activeTab}
      onChange={handleTabChange}
      renderTabBar={renderTabBar}
      key={renderOnChange ? `tabs-active-${activeTab}` : undefined}
    >
      {generateTabs()}
    </StyledAntTabs>
  );
};

const StyledAntTabs = styled(AntTabs)<{ isTabsOne: boolean }>`
  .ant-tabs-nav {
    display: ${({ isTabsOne }) => (isTabsOne ? 'none' : 'flex')};
  }

  .ant-tabs-content {
    display: block;
  }
`;

const ActiveTabButton = styled(Button)`
  padding: 0px;
  position: relative;

  background-color: ${({ theme }) => theme.colorDarkL3};
  height: 50px;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default Tabs;

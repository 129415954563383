import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { AccountsHelpers } from 'helpers/accounts';
import { StyledComponentProps } from 'typings/common';
import { FeeScheduleEntryModel } from 'typings/limitConfiguration/feeSchedule';

// components
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

interface IProps
  extends RequiredPropsForTableModel<FeeScheduleEntryModel>,
    StyledComponentProps {
  size?: string;
}

const FeeScheduleEntriesTable = (props: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo<TableColumnModel[]>(
    () => [
      {
        title: t('fee_schedule.table.currency'),
        key: 'currency',
        width: 300,
        render: (record: FeeScheduleEntryModel) => record.currencyIsoCode,
      },

      {
        title: t('fee_schedule.table.amount'),
        key: 'amount',
        width: 300,
        render: (record: FeeScheduleEntryModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.amount),
      },
    ],
    [],
  );

  return <Table {...props} columns={tableColumns} />;
};

export default FeeScheduleEntriesTable;

import React, { useMemo } from 'react';

// helpers
import { IEntity } from 'typings/application/entity';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { EntityHelpers } from 'helpers/crm/entity';
import { useVerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation/VerificationContext';

// components
import GeneralInformationShortForm, {
  FormValuesModel,
} from '../../../../../../GeneralInformationShortForm';

interface IProps {
  organization: IEntity;
  onAction?: (actionType: ActionKeys | null) => void;
}

const GeneralInformation = ({ organization, onAction }: IProps) => {
  const verificationContext = useVerificationContext();

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      submitActionType: null,
      isSave: false,
      legalName: EntityHelpers.getEntityNameByNameType(organization.names),
    };
  }, [organization]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    await verificationContext?.handleVerificationTabSubmitWithLoader(
      async () => {
        onAction && onAction(values.submitActionType);
      },
    );
  };

  return (
    <GeneralInformationShortForm
      disabled
      showOnlySaveButton
      formRef={verificationContext?.refState}
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default GeneralInformation;

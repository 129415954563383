import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FeeScheduleModel } from 'typings/limitConfiguration/feeSchedule';

// components
import FeeScheduleEntriesTable from '../FeeScheduleEntriesTable';
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

type IProps = RequiredPropsForTableModel<FeeScheduleModel>;

const FeeScheduleTable = ({ ...rest }: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo<TableColumnModel[]>(
    () => [
      {
        title: t('fee_schedule.table.name'),
        key: 'name',
        render: (record: FeeScheduleModel) => record.feeScheduleTypeName,
      },
    ],
    [],
  );

  const expandableRow = {
    rowExpandable: ({ feeScheduleEntries }: FeeScheduleModel) =>
      !!feeScheduleEntries.length,
    expandedRowRender: ({ feeScheduleEntries }: FeeScheduleModel) => (
      <FeeScheduleEntriesTable
        size="small"
        data={feeScheduleEntries}
        total={feeScheduleEntries.length}
        current={1}
      />
    ),
  };

  return <Table {...rest} columns={tableColumns} expandable={expandableRow} />;
};

export default FeeScheduleTable;

import React, { useMemo, useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import { useVerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation/VerificationContext';

// components
import { Message } from '@ui';
import LoadingWrapper from '../../../../../../../../../WrapperComponents/LoadingWrapper';
import ApplicationDocumentationForm, {
  FormValuesModel,
} from '../../../../../../ApplicationDocumentationForm';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  isViewOnly?: boolean;
  canSendMessage?: boolean;
  onAction?: (isCompleted: boolean, actionType: ActionKeys | null) => void;
}

const ApplicationDocumentation = ({
  isViewOnly,
  onboardingStatus,
  canSendMessage,
  onAction,
}: IProps) => {
  const { t } = useTranslation('common');
  const verificationContext = useVerificationContext();
  const [updateAnswersTrigger, updateAnswers] = useState<any>();

  const { response: answersResponse, loading: answersLoader } = useFetch(
    () =>
      onboardingAPI.fetchApplicationDocumentationStepAnswers(
        onboardingStatus.clientGroupId,
        onboardingStatus.crmItemId,
      ),
    [onboardingStatus, updateAnswersTrigger],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!answersResponse) {
      return null;
    }

    return onboardingAPIAdapter.generateDataForApplicationDocumentationFromOnboardingAnswers(
      answersResponse,
      onboardingStatus,
    );
  }, [answersResponse, onboardingStatus]);

  const onSubmit = async (values: FormValuesModel) => {
    await verificationContext?.handleVerificationTabSubmitWithLoader(
      async () => {
        const formattedBody =
          onboardingAPIAdapter.submitApplicationDocumentationStep(
            values,
            initialFormValues as FormValuesModel,
            onboardingStatus.clientGroupId,
            onboardingStatus.item._id,
            onboardingStatus.type,
          );
        await onboardingAPI.submitApplicationDocumentationStep(formattedBody);
        const { informationFilled } =
          await onboardingAPI.fetchStatusForOnboardingItem(
            onboardingStatus.crmItemId,
            onboardingStatus.applicationId,
          );

        Message.success(t('success_save'));
        updateAnswers({});
        onAction &&
          onAction(
            informationFilled.applicationDocumentation,
            values.submitActionType,
          );
      },
    );
  };

  return (
    <LoadingWrapper loading={answersLoader}>
      {initialFormValues && (
        <ApplicationDocumentationForm
          formRef={verificationContext?.refState}
          showOnlySaveButton
          disabled={isViewOnly}
          onSubmit={onSubmit}
          initialFormValues={initialFormValues}
          canSendMessage={canSendMessage}
        />
      )}
    </LoadingWrapper>
  );
};

export default ApplicationDocumentation;

import React, { useMemo } from 'react';

// helpers
import { IEntity } from 'typings/application/entity';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { useVerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation/VerificationContext';

// components
import IdentificationForOrganizationShortForm, {
  FormValuesModel,
} from '../../../../../../IdentificationForOrganizationShortForm';

interface IProps {
  organization: IEntity;
  onAction?: (actionType: ActionKeys | null) => void;
}

const Identification = ({ organization, onAction }: IProps) => {
  const verificationContext = useVerificationContext();

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      submitActionType: null,
      isSave: false,
      isRegulated:
        typeof organization.isRegulated === 'boolean'
          ? organization.isRegulated
          : null,
      regulationCountry: organization.regulationCountry,
    };
  }, [organization]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    await verificationContext?.handleVerificationTabSubmitWithLoader(
      async () => {
        onAction && onAction(values.submitActionType);
      },
    );
  };

  return (
    <IdentificationForOrganizationShortForm
      disabled
      showOnlySaveButton
      formRef={verificationContext?.refState}
      onSubmit={handleOnSubmit}
      initialFormValues={initialFormValues}
    />
  );
};

export default Identification;

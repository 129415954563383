import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Divider, useBreakpoint } from '@ui';

const DividerBetweenSections = () => {
  const breakpoint = useBreakpoint();

  return !breakpoint.xl ? (
    <DividerWrapper>
      <Divider />
    </DividerWrapper>
  ) : null;
};

const DividerWrapper = styled.div`
  width: 100%;
  padding: 0px 32px;
`;

export default DividerBetweenSections;

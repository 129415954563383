import React from 'react';

// helpers
import { IApplication } from 'typings/application/applications';

// constants
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { VerificationProvider } from './VerificationContext';

// components
import SubmitButtons from './SubmitButtons';
import ApplicationDocumentationWithRelationships from 'components/Forms/TemplateForms/Onboarding/ApplicationDocumentationWithRelationships';

interface IProps {
  isViewOnly: boolean;
  application: IApplication;
  onAction: (key: ActionKeys | null) => void;
}

const ApplicationDocumentation = ({
  isViewOnly,
  application,
  onAction,
}: IProps) => {
  return (
    <VerificationProvider>
      <ApplicationDocumentationWithRelationships
        application={application}
        disabled={isViewOnly}
        onAction={onAction}
        canSendMessage
      />
      {!isViewOnly && <SubmitButtons />}
    </VerificationProvider>
  );
};

export default ApplicationDocumentation;

import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { signOut } from 'redux/actions/auth';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { changeView } from 'redux/actions/view';
import { useNavigate } from 'react-router-dom';
import { colorsTheme } from 'resources/theme/styled/colors';
import { Route, routes } from 'routes/routes';
import { RoutesHelpers } from 'helpers/routes';
import { LocalizationNamespaces } from 'constants/localization';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';
import { StateModel as UserAccessStateModel } from 'redux/reducers/userAccess';
import { StateModel as ApplicationStateModel } from 'redux/reducers/applications';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import NewMessagesBadge from 'components/Additional/Messaging/NewMessagesBadge';
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';
import { ReactComponent as UserIcon } from 'resources/icons/remix-icons/user-line.svg';
import { ReactComponent as GroupIcon } from 'resources/icons/remix-icons/group-line.svg';
import { ReactComponent as LogoutIcon } from 'resources/icons/remix-icons/logout-box-r-line.svg';
import {
  Text,
  Avatar,
  IconSVG,
  Divider,
  Link,
  Col,
  Row,
  Dropdown,
  Menu,
  MenuItem,
  useBreakpoint,
} from '@ui';

const ActionsWithNavItems = () => {
  const { t } = useTranslation(LocalizationNamespaces);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const [isVisible, setIsVisible] = useState(false);
  const { contactData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );
  const { activeApplication, applications } = useSelector<
    StateModel,
    ApplicationStateModel
  >((state) => state.applications);
  const contactName = useMemo(
    () =>
      contactData ? `${contactData.firstName} ${contactData.lastName}` : '',
    [contactData],
  );
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );
  const showClientGroupSwitchOption =
    layoutVariant === 'ApprovedClientGroup' ||
    (applications && applications.length > 1);

  const onSwitch = () => {
    dispatch(changeView(() => navigate(RoutePaths.Connections_Client_Groups)));
    setIsVisible(false);
  };

  const allowedRoutes = useMemo(() => {
    function isAllowed(route: Route): boolean {
      const { metadata, access } = route;

      const addToNavigation =
        metadata?.addToProfileMenu ||
        (layoutVariant &&
          ['OnboardingClientGroup', 'NoClientGroup'].includes(layoutVariant) &&
          metadata?.addToOnboardingProfileMenu);
      const hasValidLayout =
        !access?.layout || access?.layout === layoutVariant;
      const hasValidPermission =
        !access?.permission || permissions[access.permission].isAllowed;

      return !!(addToNavigation && hasValidLayout && hasValidPermission);
    }

    const filteredRoutes = RoutesHelpers.filterRoutes(
      RoutesHelpers.getMainRoutes(routes),
      isAllowed,
    );

    return filteredRoutes.reduce<Route[]>((acc, route) => {
      if (route.children) {
        acc.push(...route.children);
      } else {
        acc.push(route);
      }

      return acc;
    }, []);
  }, [layoutVariant, permissions]);

  const renderMenuItems = (routes: Route[]) => {
    return routes.map((e) => (
      <StyledMenuItem
        key={e.path}
        itemKey={e.path}
        className={location.pathname === e.path ? 'menu-submenu-selected' : ''}
      >
        <Link
          href={e.path}
          onClick={(event) => {
            event && event.preventDefault();
            dispatch(changeView(() => navigate(e.path)));
          }}
        >
          {t(e.title.key, { ns: e.title.ns })}
        </Link>
        <RenderBadgeByRoutePath path={e.path} />
      </StyledMenuItem>
    ));
  };

  return (
    <Dropdown
      arrow
      visible={isVisible}
      placement="bottomRight"
      trigger={['click']}
      onVisibleChange={setIsVisible}
      getPopupContainer={(triggerNode: HTMLElement) =>
        (triggerNode?.parentNode as HTMLElement) || document.body
      }
      overlay={
        <StyledMenu
          getPopupContainer={(triggerNode: HTMLElement) =>
            (triggerNode?.parentNode as HTMLElement) || document.body
          }
        >
          {showClientGroupSwitchOption ? (
            <>
              <StyledRow justify="space-between">
                <StyledCol>
                  <DivAlignCenter>
                    <StyledGroupIcon
                      component={GroupIcon}
                      color={colorsTheme.colorWhite}
                    />
                    <Text>
                      <EllipsisTooltip
                        maxTextContainerWidth="130px"
                        title={activeApplication?.clientGroup.clientGroupName}
                      >
                        {activeApplication?.clientGroup.clientGroupName}
                      </EllipsisTooltip>
                    </Text>
                  </DivAlignCenter>
                </StyledCol>
                <Col>
                  <Link onClick={onSwitch}>
                    {t('switch', { ns: 'common' })}
                  </Link>
                </Col>
              </StyledRow>
              <SwitchDivider />
            </>
          ) : null}
          {renderMenuItems(allowedRoutes)}
          <StyledDivider />
          <StyledMenuItem
            key="sign-out-btn"
            itemKey="sign-out-btn"
            onClick={() => dispatch(signOut())}
          >
            <StyledSignOutIconSVG
              component={LogoutIcon}
              color={colorsTheme.colorWhite}
            />
            {t('signout.signout_button', { ns: 'auth' })}
          </StyledMenuItem>
        </StyledMenu>
      }
    >
      <ProfileWrapper align="middle" gutter={[16, 0]}>
        <Col>
          <NewMessagesBadge>
            <Avatar
              icon={
                <StyledIconSVG
                  component={UserIcon}
                  color={colorsTheme.colorWhite}
                />
              }
            />
          </NewMessagesBadge>
        </Col>
        {!breakpoint.xs && (
          <Col>
            {layoutVariant === 'ApprovedClientGroup' && (
              <Text weight="semi-bold" color={colorsTheme.colorWhite}>
                <EllipsisTooltip
                  maxTextContainerWidth="130px"
                  title={activeApplication?.clientGroup.clientGroupName}
                >
                  {activeApplication?.clientGroup.clientGroupName}
                </EllipsisTooltip>
              </Text>
            )}
            {contactName}
          </Col>
        )}
      </ProfileWrapper>
    </Dropdown>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

const ProfileWrapper = styled(Row)`
  line-height: 18px;
  color: ${({ theme }) => theme.colorLight};
  margin: ${({ theme }) => theme.marginXs} 0;

  &:hover {
    cursor: pointer;
  }

  .ant-divider-vertical {
    margin-right: ${({ theme }) => theme.marginMd};
    height: 20px;
  }
`;

const StyledMenu = styled(Menu)`
  width: 250px;
`;

const StyledRow = styled(Row)`
  padding: ${({ theme }) => theme.paddingSm} 18px;
`;

const StyledCol = styled(Col)`
  display: flex;
`;

const StyledGroupIcon = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const SwitchDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXs} 0;
  margin-top: 8px;
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0px;
  border-color: ${({ theme }) => theme.colorLight};
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  &.ant-dropdown-menu-item > a {
    color: ${({ theme }) => theme.colorLight} !important;

    &:hover {
      color: ${({ theme }) => theme.colorPrimary} !important;
      filter: 0;
    }
  }

  &.menu-submenu-selected > a {
    color: ${({ theme }) => theme.colorPrimary} !important;
    &:hover {
      color: ${({ theme }) => theme.colorPrimary} !important;
      filter: 0;
    }
  }
`;

const StyledSignOutIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default ActionsWithNavItems;

import React from 'react';
import { Menu as AntdMenu } from 'antd';
import { SubMenuProps } from './types';

/**
 * The `SubMenu` component represents a submenu inside a Menu,
 * allowing nested menu items.
 *
 * @param {SubMenuProps} props - The properties defined in `SubMenuProps`.
 */
const SubMenu: React.FC<SubMenuProps> = ({
  title,
  children,
  className,
  icon,
  itemKey,
  ...rest
}) => {
  return (
    <AntdMenu.SubMenu
      key={itemKey}
      title={title}
      className={className}
      icon={icon}
      {...rest}
    >
      {children}
    </AntdMenu.SubMenu>
  );
};

export default SubMenu;

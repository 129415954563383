import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { approvalTransactionsAPI } from 'api/approvalManagement/approvalTransactionsAPI';

// components
import { Message } from '@ui';
import ConfirmActionModalDialog, {
  FormValuesModel,
} from '../../../../../../ConfirmActionModalDialog';

enum ACTION_TYPE {
  APPROVE = 1,
  REJECT,
}

interface IProps {
  selectedActionType: ACTION_TYPE | null;
  workflowId?: string;
  handleModalClose: (wasSubmitted?: boolean) => void;
}

const ReviewTransactionModal = ({
  selectedActionType,
  workflowId,
  handleModalClose,
}: IProps) => {
  const { t } = useTranslation('accounts');

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return { reason: '' };
  }, []);

  const modalWordings = useMemo(() => {
    if (selectedActionType === ACTION_TYPE.APPROVE) {
      return {
        title: t(
          'transaction_details_modal.pending.approve_confirmation_message',
        ),
        description: t(
          'transaction_details_modal.pending.approve_description_message',
        ),
        submitButton: t('approve', { ns: 'common' }),
      };
    } else if (selectedActionType === ACTION_TYPE.REJECT) {
      return {
        title: t(
          'transaction_details_modal.pending.reject_confirmation_message',
        ),
        description: t(
          'transaction_details_modal.pending.reject_description_message',
        ),
        submitButton: t('reject', { ns: 'common' }),
      };
    }
  }, [selectedActionType]);

  const handleSubmit = async (values: FormValuesModel) => {
    const { reason } = values;

    if (selectedActionType === ACTION_TYPE.APPROVE) {
      await approveTransaction(reason);
    } else {
      await rejectTransaction(reason);
    }
  };

  const approveTransaction = async (reason: string) => {
    if (!workflowId) {
      return;
    }

    await approvalTransactionsAPI.approveTransaction(workflowId, reason);
    Message.success(
      t('transaction_details_modal.pending.success_approve_message'),
    );
  };

  const rejectTransaction = async (reason: string) => {
    if (!workflowId) {
      return;
    }

    await approvalTransactionsAPI.rejectTransaction(workflowId, reason);
    Message.success(
      t('transaction_details_modal.pending.success_reject_message'),
    );
  };

  return (
    <ConfirmActionModalDialog
      title={modalWordings?.title || ''}
      description={modalWordings?.description}
      submitButtonText={modalWordings?.submitButton || ''}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      isVisible={!!selectedActionType}
      closeCallback={handleModalClose}
    />
  );
};

export default ReviewTransactionModal;

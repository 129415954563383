import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { ContactModel } from 'typings/application/contact';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { OnboardingHelpers } from 'helpers/crm/onboarding';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import { useVerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation/VerificationContext';

// components
import { Message } from '@ui';
import LoadingWrapper from '../../../../../../../../../WrapperComponents/LoadingWrapper';
import IdentificationForIndividualForm, {
  AddressItemModel,
  FormValuesModel,
  PassportItemModel,
} from '../../../../../../IdentificationForIndividualForm';

interface IProps {
  applicationId: string;
  onboardingStatus: OnboardingStatusModel;
  isViewOnly?: boolean;
  onAction?: (isCompleted: boolean, actionType: ActionKeys | null) => void;
}

const Identification = ({
  isViewOnly,
  onboardingStatus,
  applicationId,
  onAction,
}: IProps) => {
  const { t } = useTranslation('common');
  const verificationContext = useVerificationContext();

  const { response, loading } = useFetch(() => {
    return onboardingStatus.item._id
      ? onboardingAPI.fetchIdentificationStepData(onboardingStatus.item._id)
      : null;
  }, [onboardingStatus.item._id]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response) {
      return null;
    }
    let addresses: AddressItemModel[] = [
      {
        isPrimary: true,
        type: null,
        country: null,
        city: '',
        street: '',
        state: '',
        postalCode: '',
      },
    ];

    let passports: PassportItemModel[] = [
      {
        number: '',
        country: '',
        issuedAt: null,
        expirationDate: null,
        document: [],
      },
    ];

    if (onboardingStatus.item) {
      const contact = onboardingStatus.item as ContactModel;

      if (response.addresses.data.length) {
        addresses = response.addresses.data.map((address) => ({
          _id: address._id,
          isPrimary: !!address.isPrimary,
          type: address.type || null,
          country: address.country || null,
          city: address.city,
          street: address.street,
          state: address.state,
          postalCode: address.postalCode,
          review: address.review,
          documents:
            address?.documents &&
            address.documents.map((doc) => ({
              id: doc.id,
              name: doc.files[0].name,
              fileId: doc.files[0].id,
              file: null,
            })),
        }));
      }

      if (response.passportExpiringDocuments.data.length) {
        passports = response.passportExpiringDocuments.data.map(
          OnboardingHelpers.formatExpiringDocumentToPassportFormItemModel,
        );
      }

      return {
        submitActionType: null,
        isSave: false,
        isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
        pepInformation: contact.pepInformation || '',
        isRegulated:
          typeof contact.isRegulated === 'boolean' ? contact.isRegulated : null,
        regulationCountry: contact.regulationCountry,
        addresses,
        passports,
        disabledPep: true,
        nationality: contact.nationality || [],
      };
    } else {
      return {
        submitActionType: null,
        isSave: false,
        isPEP: null,
        pepInformation: '',
        isRegulated: null,
        regulationCountry: [],
        addresses,
        passports,
        disabledPep: true,
        nationality: [],
      };
    }
  }, [onboardingStatus, response]);

  const onSubmit = async (values: FormValuesModel) => {
    if (!isViewOnly) {
      await verificationContext?.handleVerificationTabSubmitWithLoader(
        async () => {
          const formattedBody =
            onboardingAPIAdapter.submitIdentificationForIndividualStep(values);
          await onboardingAPI.submitIdentificationForIndividualStep(
            formattedBody,
            applicationId,
            onboardingStatus.item._id,
          );
          Message.success(t('success_save'));
        },
      );
    }
    onAction && onAction(true, values.submitActionType);
  };

  return (
    <LoadingWrapper loading={loading}>
      {initialFormValues && (
        <IdentificationForIndividualForm
          formRef={verificationContext?.refState}
          showOnlySaveButton
          disabled={isViewOnly}
          onSubmit={onSubmit}
          initialFormValues={initialFormValues}
        />
      )}
    </LoadingWrapper>
  );
};
export default Identification;

import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useRef,
} from 'react';

interface VerificationContextData {
  refState: any;
  isLoading: boolean;
  setLoading: (newStatus: boolean) => void;
  handleVerificationTabSubmitWithLoader: <T>(
    fn: () => Promise<T>,
  ) => Promise<T>;
}

const VerificationContext = React.createContext<VerificationContextData | null>(
  null,
);

const useVerificationContext = (): VerificationContextData | null => {
  const context = useContext(VerificationContext);
  return context;
};

const VerificationProvider = ({ children }: { children: React.ReactNode }) => {
  const refState = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setLoading = useCallback((newStatus: boolean) => {
    setIsSubmitting(newStatus);
  }, []);

  const handleVerificationTabSubmitWithLoader = useCallback(
    async <T,>(fn: () => Promise<T>) => {
      setIsSubmitting(true);
      try {
        return await fn();
      } finally {
        setIsSubmitting(false);
      }
    },
    [],
  );

  const value = useMemo(
    () => ({
      refState,
      isLoading: isSubmitting,
      setLoading,
      handleVerificationTabSubmitWithLoader,
    }),
    [setLoading, handleVerificationTabSubmitWithLoader, isSubmitting, refState],
  );

  return (
    <VerificationContext.Provider value={value}>
      {children}
    </VerificationContext.Provider>
  );
};

export { VerificationContext, VerificationProvider, useVerificationContext };

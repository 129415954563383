import React, { memo, useState } from 'react';

// helpers
import APIConfig from 'config/api';
import useTranslation from 'hooks/useTranslation';
import { SessionHelpers } from 'helpers/session';
import { DocumentHelpers } from 'helpers/documents';
import { LocalStorageHelpers } from 'helpers/storages/localStorage';

// components
import { Button, Dropdown, Menu, MenuItem } from '@ui';

interface IProps {
  accountNumber: string;
  accountTransfersParams: AccountTransfersParamsModel;
}

export interface AccountTransfersParamsModel {
  page: number;
  endDate: number;
  startDate: number;
  accountNumber: string;
}

const ExportAccountStatement = memo(
  ({ accountNumber, accountTransfersParams }: IProps) => {
    const { t } = useTranslation('common');
    const [loader, setLoader] = useState(false);

    const onExportClick = async (fileType: 'pdf' | 'csv') => {
      setLoader(true);
      const token = LocalStorageHelpers.getAccessToken();
      const fingerprint = await SessionHelpers.generateFingerprint();
      const link =
        fileType == 'pdf'
          ? `${APIConfig.accountingApi}/report/client-statement/${accountNumber}/pdf?fromDate=${
              accountTransfersParams.startDate
            }&toDate=${accountTransfersParams.endDate}&tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`
          : `${APIConfig.accountingApi}/report/client-statement/${accountNumber}/csv?fromDate=${
              accountTransfersParams.startDate
            }&toDate=${accountTransfersParams.endDate}&tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
      await DocumentHelpers.downloadDocumentByURL(link, accountNumber);
      setLoader(false);
    };

    return (
      <>
        <Dropdown
          overlay={
            <Menu>
              <MenuItem
                key="pdf"
                itemKey="pdf"
                onClick={() => onExportClick('pdf')}
              >
                {t('file_types.PDF')}
              </MenuItem>
              <MenuItem
                key="csv"
                itemKey="csv"
                onClick={() => onExportClick('csv')}
              >
                {t('file_types.CSV')}
              </MenuItem>
            </Menu>
          }
        >
          <Button loading={loader}>{t('export')}</Button>
        </Dropdown>
      </>
    );
  },
);

export default ExportAccountStatement;

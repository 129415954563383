import React, { useCallback, useEffect, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { Graph } from '@antv/g6';
import { GraphNode } from '..';

// components
import { Menu, MenuItem } from '@ui';

interface IProps {
  graph?: Graph;
  node: GraphNode;

  x: number;
  y: number;

  onClose: () => void;
  onAction: (key: string) => void;
}

export interface EllipsisOptionModel {
  key: string;
  label: string;
  hidden?: boolean;
}

const NodeActions = ({ node, graph, x, y, onAction, onClose }: IProps) => {
  const options = useMemo(() => {
    return (
      node.ellipsisMenuOptions?.filter(({ hidden }) => {
        return !hidden;
      }) || []
    );
  }, [node]);

  // Handle menu close when clicked outside
  useEffect(() => {
    if (node) {
      // Move "addEventListener" to the end of call stack to not capture current click event
      setTimeout(() => {
        document.addEventListener('click', handleClickOutside);
      });
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [node]);

  const handleSelect = ({ key }: any) => {
    onAction(key);
    onClose();
  };

  const handleClickOutside = useCallback(() => {
    onClose();
  }, [onClose]);

  const renderActions = () => {
    return options?.map(({ key, label }) => (
      <MenuItem key={key} itemKey={key}>
        {label}
      </MenuItem>
    ));
  };

  if (!node || !graph || !options.length) {
    return null;
  }

  const point = graph.getCanvasByPoint(x, y);

  return (
    <StyledMenu
      style={{ left: point.x, top: point.y }}
      mode="vertical"
      onSelect={handleSelect}
    >
      {renderActions()}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  position: absolute;
  min-width: 200px;
`;

export default NodeActions;

import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useVerificationContext } from '../VerificationContext';

// components
import { Button, Col, Row } from '@ui';

const ButtonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

const SubmitButtons = () => {
  const verificationContext = useVerificationContext();
  const { t } = useTranslation('common');

  const onSaveAndExitClick = async () => {
    if (verificationContext?.refState.current) {
      await verificationContext.refState.current.setValues(
        {
          ...verificationContext.refState.current.values,
          isSave: true,
          submitActionType: 'save-exit',
        },
        false,
      );
      verificationContext.refState.current.submitForm();
    }
  };

  const onBackClick = async () => {
    if (verificationContext?.refState.current) {
      await verificationContext.refState.current.setValues(
        {
          ...verificationContext.refState.current.values,
          isSave: true,
          submitActionType: 'save-back',
        },
        false,
      );
      verificationContext.refState.current.submitForm();
    }
  };

  return (
    <StyledRow justify="end" gutter={[16, 16]}>
      <Col {...ButtonGridSizes}>
        <StyledButton
          type="bordered"
          size="large"
          loading={verificationContext?.isLoading}
          disabled={verificationContext?.isLoading}
          onClick={onBackClick}
        >
          {t('back')}
        </StyledButton>
      </Col>
      <Col {...ButtonGridSizes}>
        <StyledButton
          type="primary"
          size="large"
          loading={verificationContext?.isLoading}
          disabled={verificationContext?.isLoading}
          onClick={onSaveAndExitClick}
        >
          {t('save_and_exit')}
        </StyledButton>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default SubmitButtons;
